import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FetaureImgTwo = () => {
  return (
    <>
      <section className='feature-section two-bg-dark-light ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-6 col-md-6'>
              <div
                className='image-wrap mb-5 mb-md-0 mb-lg-0 mb-xl-0'
                data-aos='fade-right'
              >
                <img
                  src='assets/img/dashboard-img.png'
                  alt='feature img'
                  className='img-fluid shadow rounded-custom'
                />
              </div>
            </div>
            <div className='col-lg-5 col-md-6'>
              <div
                className='feature-content-wrap'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <SectionTitle
                  subtitle='Advanced Features'
                  title='Automate the process'
                  description='By using our API you can automate the process of creating documents from your templates.'
                  leftAlign
                />
                <ul className='list-unstyled mt-5'>
                <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-danger rounded me-4'>
                      <i className='fas fa-fingerprint text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>API Interface</h3>
                      <p>
                        Generate documents, manage templates and datasources from your own application through our API.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-primary rounded me-4'>
                      <i className='fas fa-bezier-curve text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Batch production</h3>
                      <p>
                        Produce up to 1000 documents at once with our batch production feature.
                      </p>
                    </div>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FetaureImgTwo;
