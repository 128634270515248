import React from 'react';
import PageMeta from '../components/common/PageMeta';
import CtaTwo from '../components/cta/CtaTwo';
import Customer from '../components/customer/Customer';
import FeatureImgThree from '../components/features/FeatureImgThree';
import FeatureOne from '../components/features/FeatureOne';
import FeatureTwo from '../components/features/FeatureTwo';
import FetaureImgTwo from '../components/features/FetaureImgTwo';
import HeroThree from '../themes/index3/HeroThree';
import IntegrationTwo from '../components/integration/IntegrationTwo';
import Promo from '../components/promo/Promo';
import TestimonialTwo from '../components/testimonial/TestimonialTwo';
import WorkProcess from '../components/work-process/WorkProcess';
import Footer from '../layout/Footer/Footer';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import FeatureImgFour from '../components/features/FeatureImgFour';

const FeatureApp = () => {
  return (
    <Layout>
      <PageMeta title='DocBuilder | Build documents faster' />
      <Navbar classOption='navbar-light' />
      <HeroThree />
      

      <FeatureOne />


      
      <Footer footerLight />
    </Layout>
  );
};

export default FeatureApp;
