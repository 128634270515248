import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FaqTwo = () => {
  return (
    <>
      <section className='faq-section ptb-120 bg-light'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12'>
              <SectionTitle subtitle='FAQ' title='Frequently Asked Questions' description="Can't find your question below? Don't hesitate to contact us at support@docbuilder.io"/>
              
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12'>
              <div className='accordion faq-accordion' id='accordionExample'>
                <div className='accordion-item border border-2 active'>
                  <h5 className='accordion-header' id='faq-1'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-1'
                      aria-expanded='true'
                    >
                      What is considered a document?
                    </button>
                  </h5>
                  <div
                    id='collapse-1'
                    className='accordion-collapse collapse show'
                    aria-labelledby='faq-1'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      TBD
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-2'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-2'
                      aria-expanded='false'
                    >
                      What happens if I go over my limit?
                    </button>
                  </h5>
                  <div
                    id='collapse-2'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-2'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      TBD
                    </div>
                  </div>
                </div>


                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-5'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-5'
                      aria-expanded='false'
                    >
                      What payment methods are accepted?
                    </button>
                  </h5>
                  <div
                    id='collapse-5'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-5'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      The application will send an invoice to the registered email address. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqTwo;
