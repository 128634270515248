import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureImgThree = ({paddingTop}) => {
  return (
    <>
      <section className={`feature-section-two ${paddingTop?'ptb-120':'pt-60 pb-120'} `}>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-5 col-md-12'>
              <SectionTitle
                subtitle='Features'
                title='Design and build documents faster'
                description='Effortlessly transfer your templates into documents with a seamless and automated process.'
              />
              <div>
                <ul className='list-unstyled mt-5'>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-primary rounded me-4'>
                      <i className='fas fa-bezier-curve text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Pixel Perfect Design</h3>
                      <p>
                        Maintain full control over the look and feel of your documents.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-danger rounded me-4'>
                      <i className='fas fa-fingerprint text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Simple and intuitive interface</h3>
                      <p>
                        Don't lose time learning how to use the software. Our interface is intuitive and easy to use.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap position-relative d-flex flex-column align-items-end'>
                <ul className='img-overlay-list list-unstyled position-absolute'>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='50'
                  >
                    <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                    <h6 className='mb-0'>Create a Free Account</h6>
                  </li>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='100'
                  >
                    <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                    <h6 className='mb-0'>Select an example templates</h6>
                  </li>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='150'
                  >
                    <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                    <h6 className='mb-0'>Start building documents</h6>
                  </li>
                </ul>
                <img
                  src='assets/img/feature-img3.jpg'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgThree;
