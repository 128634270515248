import React from 'react';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import CtaSubscribe from '../components/cta/CtaSubscribe';
import Faq from '../components/faqs/Faq';
import Price from '../components/prices/Price';
import TestimonialTwo from '../components/testimonial/TestimonialTwo';
import Footer from '../layout/Footer/Footer';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const Pricing = () => {
  return (
    <Layout>
      <PageMeta title='Pricing - Software &amp; IT Solutions HTML Template ' />
      <Navbar />
      <PageHeader
        title='Pick the best plan for you.'
        desc=""
      />
      <Price paddingTop='ptb-120'/>
      <Faq />
      <Footer footerLight />
    </Layout>
  );
};

export default Pricing;
