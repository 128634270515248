import React from 'react';
import SectionTitle from '../common/SectionTitle';

const Promo = ({bgWhite}) => {
  return (
    <>
      <section className={`promo-section ptb-120 ${bgWhite?'bg-white':'bg-light'} `}>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-6'>
              <SectionTitle
                title='Our Customers Get Results'
                description=''
                centerAlign
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-lg-6 mt-6 mt-lg-0 mt-md-0'>
              <div
                className='bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom'
                data-aos='fade-up'
                data-aos-delay='100'
              >
                <div className='promo-card-info mb-4'>
                  <h3 className='display-5 fw-bold mb-4 text-white'>
                    <i className='far fa-fingerprint text-warning me-2'></i> 10x 
                  </h3>
                  <p className='text-white'>
                    Deliver documents 10x faster than before, keeping full control over the look and feel of your documents.
                  </p>
                </div>
                <div className='mt-auto'>
                
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-6 mt-6 mt-lg-0 mt-md-0'>
              <div
                className='bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom'
                data-aos='fade-up'
                data-aos-delay='150'
              >
                <div className='promo-card-info mb-4'>
                  <h3 className='display-5 fw-bold mb-4 text-white'>
                    <i className='far fa-paper-plane text-warning me-2'></i> 
                  </h3>
                  <p className='text-white'>
                    Avoid human error from manually copying and pasting data into documents, and save time by automating the process.
                  </p>
                </div>
                <div className='mt-auto'>
                 
                </div>
              </div>
            </div>
       
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
