import React from 'react';
import PageMeta from '../components/common/PageMeta';
import CtaTwo from '../components/cta/CtaTwo';
import Customer from '../components/customer/Customer';
import FeatureImgThree from '../components/features/FeatureImgThree';
import FetaureImgTwo from '../components/features/FetaureImgTwo';
import HeroThree from '../themes/index3/HeroThree';
import IntegrationTwo from '../components/integration/IntegrationTwo';
import Promo from '../components/promo/Promo';
import TestimonialTwo from '../components/testimonial/TestimonialTwo';
import WorkProcess from '../components/work-process/WorkProcess';
import Footer from '../layout/Footer/Footer';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const HomeDesktopApp = () => {
  return (
    <Layout>
      <PageMeta title='DocBuilder | Build documents faster' />
      <Navbar classOption='navbar-light' />
      <HeroThree />
      <Promo />
      <FeatureImgThree paddingTop />
      <FetaureImgTwo />
      <WorkProcess />
      <CtaTwo />
      <Footer footerLight />
    </Layout>
  );
};

export default HomeDesktopApp;
