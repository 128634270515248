import React from 'react';
import SectionTitle from '../common/SectionTitle';

const WorkProcess = () => {
  return (
    <>
      <section className='work-process ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                subtitle='Process'
                title='Setup document flows'
                description=''
                centerAlign
              />
            </div>
          </div>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-5 col-md-12 order-1 order-lg-0'>
              <div className='img-wrap'>
                <img
                  src='assets/img/office-img-1.jpg'
                  alt='work process'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 order-0 order-lg-1'>
              <ul className='work-process-list list-unstyled'>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-folder-tree fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Step 1</span>
                    <h3 className='h5 mb-2'>Build a template</h3>
                    <p>
                      Document templates serve as blueprints for efficient and consistent document creation, offering a structured framework that simplifies the process.
                    </p>
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-bezier-curve fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Step 2</span>
                    <h3 className='h5 mb-2'>Define a datasource</h3>
                    <p>
                      A datasource is a structured data repository that serves as the source for document content, providing the necessary information to populate the document.
                    </p>
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-layer-group fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Step 3</span>
                    <h3 className='h5 mb-2'>Generate documents</h3>
                    <p>
                      Define the document formats (Word, PDF, Excel ..) and a naming convention by linking the template to the datasource, and generate the documents with a single click.
                    </p>
                  </div>
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkProcess;
