import React from 'react';
import { Link } from 'react-router-dom';





const ContactsForm = () => {
  const [state, setState] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);


  const submitForm = () => {
    console.log("username", state);
    console.log("email", email);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify({ username: state, email: email})
      };
      fetch('https://api.docbuilder.io', requestOptions)
          .then(response => response.json())
          .then(    setSubmitted(true));
  } 


  return (
    <>
      <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1'>
        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
          <h3 className='fw-medium h4'>
            Complete the form below to sign up
          </h3>




          <form action='#' className='mt-4 register-form'>
            <div className='row'>
              <div className="col-sm-12 pb-3">
                <input
                  type='text'
                  className='form-control'
                  placeholder='Username'
                  aria-label='username'
                  value={state}
                  onChange={(event) => {
                    setState(event.target.value);
                  }}
                />
              </div>
      
              <div className="col-sm-12 pb-3">
                <input
                  type='text'
                  className='form-control'
                  placeholder='Email'
                  aria-label='email'
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </div>
   
              <div className="col-sm-12 pb-3">
                <input
                  type='password'
                  className='form-control'
                  placeholder='Password'
                  aria-label='password'
                />
              </div>
              <div className="col-sm-12 pb-3">
                <input
                  type='password'
                  className='form-control'
                  placeholder='Repeat Password'
                  aria-label='Repeat password'
                />
              </div>
              <div className='col-sm-12 pb-3 pl-2'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='flexCheckChecked'
                  />
                  <label
                    className='form-check-label small'
                    htmlFor='flexCheckChecked'
                  >
                    Yes, I agree to the terms of service and privacy policy
                  </label>
                </div>
              </div>

              {submitted && <div >Form submitted successfully, we will reach out shortly. </div>}

              {
                !submitted && 
                <div className='col-12'>
                  <button
                    onClick={submitForm}
                    className='btn btn-primary mt-4 d-block w-100'
                  >
                    Get Started
                  </button>
                </div>
              }
              
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactsForm;
